import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import _ from 'lodash';
import AggridContextMenu from 'components/AggridContextMenu';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';

const Table = (props) => {
  const { context, objectName, list, setList, columnDefs, rowData, contextMenuGeneration, isLoading, getRowStyle } = props;

  const [errors, setErrors] = useState({});
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [agGridContext, setAgGridContext] = useState(context);
  const [isEditing, setIsEditing] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);

  const prevRowDataRef = useRef();

  const needsSuppressRowTransform = useMemo(() => {
    return columnDefs.some(
      (col) => col.cellRendererFramework === DraggableListCellRenderer
    );
  }, [columnDefs]);
  
  useEffect(() => {
    setAgGridContext({ ...context, isLoading: isLoading,  prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = rowData;
  }, [context, rowData, isLoading]); 

  const baseColumnConfig = {
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  }

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    
    params.api.sizeColumnsToFit();
  }, []);

  const onCellValueChanged = useCallback(params => {
    setList({ type: 'update', payload: params.data });
  }, [setList]);

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);
  
  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onCellFocused = useCallback((params) => {
    if (!isEditing) {
      gridApi.stopEditing();
    }
  }, [isEditing, gridApi]);

  const onCellContextMenu = (event) => {
    setContextMenu({
      x: event.event.pageX,
      y: event.event.pageY,
      rowData: event.data,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  const getRowHeight = useCallback((params) => {
    const cachedHeights = params.data.cachedHeights || {};
    let maxHeight = cachedHeights[params.node.id] || 50;
  
    if (!cachedHeights[params.node.id]) {
      columnDefs.forEach((col) => {
        if (col.variableHeight) {
          const value = params.data[col.field];
          let contentHeight = 50;
          if (Array.isArray(value)) {
            contentHeight = value.length * 45;
          } else if (typeof value === 'string') {
            const lineHeight = 20;
            const lines = Math.ceil(value.length / 50);
            contentHeight = lines * lineHeight + 20;
          }
          maxHeight = Math.max(maxHeight, contentHeight);
        }
      });
      params.data.cachedHeights = { ...cachedHeights, [params.node.id]: maxHeight };
    }
    return maxHeight;
  }, [columnDefs]);
  
  
  return (
    <div className="mt-2 ag-theme-alpine ag-row grid-height-800" onContextMenu={onContextMenu} > 
        <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        suppressRowTransform={needsSuppressRowTransform}
        getRowHeight={getRowHeight}    
        onGridReady={onGridReady}
        context={agGridContext}
        onCellValueChanged={onCellValueChanged}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onCellContextMenu={onCellContextMenu}
        onCellFocused={onCellFocused}
        getRowStyle={getRowStyle}
        rowBuffer={15}
        domLayout="autoHeight"
        />
        {contextMenu && (
        <AggridContextMenu
            buttonLabel={'Refine ' + objectName}
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={closeContextMenu}
            onGenerate={() => {
            contextMenuGeneration(contextMenu.rowData).then(updatedRow => {
                setList({type: 'replace', payload: {old: contextMenu.rowData, new: updatedRow}});
                closeContextMenu();
            }).catch(error => {
                console.error('Error refining Design Criteria: ', error);
            });
            }}
            />
        )}
    </div>
  );
}

export default Table;
