import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import ClassificationDropdownEditor from 'components/ClassificationDropdownEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import Table from 'components/Table';
import CollapsibleEvaluationAgGrid from 'components/CollapsibleAgGrid ';
import DeleteRowButtonRenderer from 'components/ButtonRenderer/DeleteRowButtonRenderer';
import { validateAgGridColumn } from 'lib/validation';
import { validateFunctions, validateOrganizationUnits } from 'lib/validation';

const FunctionUnitAllocation = (props) => {
  const { client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, designCriteria, blueprint, functions, organizationUnits, functionUnitAllocation, setFunctionUnitAllocation } = props;

  const [isGeneratingFunctionUnitAllocation, setIsGeneratingFunctionUnitAllocation] = useState(false);
  const [isEvaluatingFunctionUnitAllocation, setIsEvaluatingFunctionUnitAllocation] = useState(false);
  const [functionUnitAllocationEvaluation, setFunctionUnitAllocationEvaluation] = useState({});
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  const baseColumnConfig = useMemo(() => ({
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    cellStyle: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      wordBreak: 'keep-all'
    },
  }), []);

  const baseColumnDefs = useMemo(() => [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig },
    { headerName: 'Function', field: 'function_name', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'function_name'), 
      ...baseColumnConfig },
      { headerName: 'Organization Unit', field: 'organization_unit_name', 
        cellRendererFramework: LoadingCellRenderer, 
        cellClassRules: {
          'validation-error': (params) => !params.value || params.value.trim() === ''
        }, 
        valueSetter: (params) => validateAgGridColumn(params, 'function_name'), 
        ...baseColumnConfig },
    { headerName: 'Expertise Fit', field: 'expertise_fit',
      cellRendererFramework: LoadingCellRenderer,
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      ...baseColumnConfig,
      width: 130, flex: 0 },
    { headerName: 'Strategy Alignment', field: 'strategy_alignment',
      cellRendererFramework: LoadingCellRenderer,
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      ...baseColumnConfig,
      width: 170, flex: 0 },
    { headerName: 'Independence', field: 'independence',
      cellRendererFramework: LoadingCellRenderer,
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      ...baseColumnConfig,
      width: 130, flex: 0 },
    { headerName: 'Workload Balance', field: 'workload_balance',
      cellRendererFramework: LoadingCellRenderer,
      cellStyle: { textAlign: 'center', justifyContent: 'center' },
      ...baseColumnConfig,
      width: 170, flex: 0 },
    { headerName: 'Reasoning', field: 'reasoning', 
      cellRendererFramework: LoadingCellRenderer,
      wrapText: true, 
      autoHeight: true, 
      ...baseColumnConfig,
      flex: 2 },
  ], [baseColumnConfig]);

  const functionColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'delete_row', 
      cellRenderer: params => <DeleteRowButtonRenderer {...params} list={functionUnitAllocation} setList={setFunctionUnitAllocation} />, 
      cellClass: 'delete-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, functionUnitAllocation, setFunctionUnitAllocation]);

  const evaluationColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'action_button', 
      cellRenderer: params => (
        <EvaluationActionButtonRenderer 
          {...params} 
          state={functionUnitAllocation} 
          setState={setFunctionUnitAllocation} 
          evaluation={functionUnitAllocationEvaluation} 
          setEvaluation={setFunctionUnitAllocationEvaluation}
        />
      ), 
      cellRendererParams: {
        options: ['add', 'move', 'remove'],
        icons: ['add_circle', 'arrow_circle_down', 'remove_circle'],
        colors: ['green', 'blue', 'red'],
      },
      cellClass: 'add-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, functionUnitAllocation, setFunctionUnitAllocation, functionUnitAllocationEvaluation, setFunctionUnitAllocationEvaluation]);

  const EvaluationActionButtonRenderer = ({ api, node, data, state, setState, evaluation, setEvaluation }) => {
    const action = data['recommeded_action_type'];
    const handleRecommendedAction = (event, action) => {
      if (action === 'add') {
        setState({ type: 'prepend', payload: [data] });
      } else if (action === 'move') {
        setState({ type: 'prepend', payload: [data] });  
        setState({ type: 'remove', payload: data });
      } else if (action === 'remove') {
        setState({ type: 'remove', payload: data });
      }
      const newState = { ...evaluation, recommended_function_unit_allocation: evaluation.recommended_function_unit_allocation.filter(obj => obj.id !== data.id) };
      setEvaluation(newState);
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={(e) => {handleRecommendedAction(e, action)}} className={`material-symbols-outlined button__icon button__icon-${action === 'add' ? 'green' : action === 'move' ? 'blue' : 'red'}`}>
          {action === 'add' ? 'add_circle' : action === 'move' ? 'arrow_circle_down' : 'remove_circle'}
        </span>
    );
  };
  
  const rowData = useMemo(() => {
    if (Array.isArray(functionUnitAllocation) && Array.isArray(functions) && Array.isArray(organizationUnits)) {
      return functionUnitAllocation.map(functionUnitAllocation => ({
        id: functionUnitAllocation.id,
        function_id: functionUnitAllocation.function_id,
        function_name: functions.filter(func => String(func.id) === String(functionUnitAllocation.function_id))[0]?.name,
        orgniazation_unit_id: functionUnitAllocation.organization_unit_id,
        organization_unit_name: organizationUnits.filter(unit => String(unit.id) === String(functionUnitAllocation.organization_unit_id))[0]?.name,
        expertise_fit: functionUnitAllocation.expertise_fit,
        strategy_alignment: functionUnitAllocation.strategy_alignment,
        independence: functionUnitAllocation.independence,
        workload_balance: functionUnitAllocation.workload_balance,
        reasoning: functionUnitAllocation.reasoning,
      }));
    }
    return [];
  }, [functionUnitAllocation, functions, organizationUnits]);

  const evaluationRowData = useMemo(() => {
    if (Array.isArray(functionUnitAllocationEvaluation?.recommended_function_unit_allocation) && Array.isArray(functions) && Array.isArray(organizationUnits)) {
      return functionUnitAllocationEvaluation?.recommended_function_unit_allocation.map(functionUnitAllocation => ({
        id: functionUnitAllocation.id,
        function_id: functionUnitAllocation.function_id,
        function_name: functions.filter(func => String(func.id) === String(functionUnitAllocation.function_id))[0]?.name,
        organization_unit_id: functionUnitAllocation.organization_unit_id,
        organization_unit_name: organizationUnits.filter(unit => String(unit.id) === String(functionUnitAllocation.organization_unit_id))[0]?.name,
        expertise_fit: functionUnitAllocation.expertise_fit,
        strategy_alignment: functionUnitAllocation.strategy_alignment,
        independence: functionUnitAllocation.independence,
        workload_balance: functionUnitAllocation.workload_balance,
        reasoning: functionUnitAllocation.reasoning,
        recommeded_action_type: functionUnitAllocation.recommeded_action_type,
      }));
    }
    return [];
  }, [functionUnitAllocationEvaluation, functions, organizationUnits]);

  const addNewRow = () => {
    const newRow = {
      id: generateuniqueIshId(),
      function_id: '',
      organization_unit_id: '',
      rationale: ''
    };
    setFunctionUnitAllocation({type: 'prepend', payload: [newRow] });
    return newRow;
  };

  const generateFunctionUnitAllocation = async (
  ) => {
    if (isGeneratingFunctionUnitAllocation) return;

    if (!validateFunctions(functions)) {
      alert('Ensure all current Functions are valid before evaluating.');
      return;
    }

    if (!validateOrganizationUnits(organizationUnits)) {
      alert('Ensure all current Organization Units are valid before evaluating.');
      return;
    }

    setIsGeneratingFunctionUnitAllocation(true);
    const blankRow = addNewRow();
    setIsLoading({ type: 'add_to_list', payload: blankRow.id });

    const apiPayload = {
        'prompt_template': 'generate_function_unit_allocation',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'function_unit_allocation': functionUnitAllocation ? functionUnitAllocation : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedFunctionUnitAllocation = apiResponse.message;

      try {
        setFunctionUnitAllocation({ type: 'overwrite', payload: generatedFunctionUnitAllocation } );
        setIsGeneratingFunctionUnitAllocation(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingFunctionUnitAllocation(false);
      }

    } catch (error) {
        console.error('Error generating Function-Unit Allocation:', error);
    }
    setIsGeneratingFunctionUnitAllocation(false);
    setIsLoading({ type: 'remove_from_list', payload: blankRow.id });
    setFunctionUnitAllocation({ type: 'remove', payload: blankRow });
  };

const evaluateFunctionAllocation = async (
  ) => {
    if (isGeneratingFunctionUnitAllocation) return;

    if (!validateFunctions(functions)) {
      alert('Ensure Functions fromt he Functions tab are valid before evaluating.');
      return;
    }

    if (!validateOrganizationUnits(organizationUnits)) {
      alert('Ensure the Organization Units from the Units tab are valid before evaluating.');
      return;
    }

    setIsEvaluatingFunctionUnitAllocation(true);

    const apiPayload = {
        'prompt_template': 'evaluate_function_unit_allocation',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
        'organization_units': organizationUnits ? organizationUnits : [],
        'function_unit_allocation': functionUnitAllocation ? functionUnitAllocation : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const functionfunctionUnitAllocationEvaluation = apiResponse.message;

      try {
        setFunctionUnitAllocationEvaluation(functionfunctionUnitAllocationEvaluation);
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating design criteria:', error);
    }
    setIsEvaluatingFunctionUnitAllocation(false);
  };

  return (
    <div className="define-tab">
      <div className="function-tab-header">
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <button 
          onClick={() => 
          generateFunctionUnitAllocation()} 
          disabled={isGeneratingFunctionUnitAllocation} 
          className="button"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          Generate function-unit allocation
          {isGeneratingFunctionUnitAllocation ? (
            <Loading style={{ marginLeft: 'auto', height: '35px' }} />
          ) : (
            <span className="material-symbols-outlined">neurology</span>
          )}
        </button>
        <ExportToExcel 
          buttonLabel='Export Function-Unit Allocation' 
          data={functionUnitAllocation} 
          fileName={name + ' Function-Unit Allocation from Reconfig'} 
          sheetName={name + ' Function-Unit Allocation'} 
          fieldsToExport={['function_name', 'organization_unit_name', 'rationale']}
          className="button ms-2"
        />
      </div>
      <CollapsibleEvaluationAgGrid 
        objectName="FunctionUnitAllocation"
        evaluation={functionUnitAllocationEvaluation?.alignment_evaluation}
        evaluationQuality={functionUnitAllocationEvaluation?.alignment_evaluation_quality}
        coverage={functionUnitAllocationEvaluation?.coverage_evaluation}
        coverageQuality={functionUnitAllocationEvaluation?.coverage_evaluation_quality}
        columnDefs={evaluationColumns}
        rowData={evaluationRowData}
        evaluationFunction={evaluateFunctionAllocation}
        isLoading={isLoading}
        isEvaluating={isEvaluatingFunctionUnitAllocation}
        setIsEvaluating={setIsEvaluatingFunctionUnitAllocation}
        validatedData={functionUnitAllocation}
      />
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Function-Unit Allocation</h2>
      </div>
      <div>
        <div className="function-unit-allocation-label">
          {functionUnitAllocation?.length} / {functions?.length} functions allocated
        </div>
      </div>
      <Table
        objectName="FunctionUnitAllocation"
        listTitle="FunctionUnitAllocation"
        list={functionUnitAllocation}
        setList={setFunctionUnitAllocation}
        columnDefs={functionColumns}
        rowData={rowData}
        isLoading={isLoading}
      />
    </div>
  );
}

export default FunctionUnitAllocation;
