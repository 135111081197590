import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import ClassificationDropdownEditor from 'components/ClassificationDropdownEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import Table from 'components/Table';
import CollapsibleEvaluationAgGrid from 'components/CollapsibleAgGrid ';
import DeleteRowButtonRenderer from 'components/ButtonRenderer/DeleteRowButtonRenderer';
import { validateAgGridColumn, validateFunctions } from 'lib/validation';

const FunctionsTab = (props) => {
  const { client, name, website, businessDetails, missionStatement, 
    strategyArena, strategyVehicle, strategyDifferentiator, 
    strategyStaging, strategyEconomicLogic, designCriteria, blueprint, functions, setFunctions } = props;

  const [isGeneratingFunctions, setIsGeneratingFunctions] = useState(false);
  const [isEvaluatingFunctions, setIsEvaluatingFunctions] = useState(false);
  const [functionsEvaluation, setFunctionsEvaluation] = useState({});
  const [showImport, setShowImport] = useState(false);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);

  const baseColumnConfig = useMemo(() => ({
    editable: true,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    cellClass: '.ag-cell-prevent-word-split',
  }), []);

  const baseColumnDefs = useMemo(() => [
    { headerName: 'ID', field: 'id', hide: true, ...baseColumnConfig },
    { headerName: '', field: 'emoji', ...baseColumnConfig, width: 30, flex: 0 },
    { headerName: 'Name', field: 'name', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'name'), 
      ...baseColumnConfig },
    { headerName: 'Description', field: 'description', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'description'), 
      wrapText: true, 
      autoHeight: true, 
      ...baseColumnConfig },
    { headerName: 'Strategic value', field: 'strategy_link', 
      cellRendererFramework: LoadingCellRenderer, 
      cellClassRules: {
        'validation-error': (params) => !params.value || params.value.trim() === ''
      }, 
      valueSetter: (params) => validateAgGridColumn(params, 'strategy_link'), 
      wrapText: true, 
      autoHeight: true, 
      ...baseColumnConfig },
    {
      headerName: 'Classification',
      field: 'classification',
      editable: true,
      cellRendererFramework: ClassificationDropdownEditor,
      cellEditorParams: {
        options: ['Strategic', 'Support'],
        isLoading: isLoading,
      },
      ...baseColumnConfig
    }
    /*{ headerName: 'Inputs', field: 'inputs', cellRendererFramework: DraggableListCellRenderer, ...baseColumnConfig, variableHeight: true },
    { headerName: 'Outputs', field: 'outputs', cellRendererFramework: DraggableListCellRenderer, ...baseColumnConfig, variableHeight: true },*/
  ], [baseColumnConfig]);

  const functionColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'delete_row', 
      cellRenderer: params => <DeleteRowButtonRenderer {...params} list={functions} setList={setFunctions} />, 
      cellClass: 'delete-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, functions, setFunctions]);

  const evaluationColumns = useMemo(() => [
    ...baseColumnDefs,
    { 
      headerName: '', 
      field: 'add_row', 
      cellRenderer: params => (
        <KeepRowButtonRenderer 
          {...params} 
          state={functions} 
          setState={setFunctions} 
          evaluation={functionsEvaluation} 
          setEvaluation={setFunctionsEvaluation} 
        />
      ), 
      cellClass: 'add-row-button-cell', 
      width: 50, 
      flex: 0 
    }
  ], [baseColumnDefs, functions, setFunctions, functionsEvaluation, setFunctionsEvaluation]);
  
  const rowData = useMemo(() => {
    if (Array.isArray(functions)) {
      return functions.map(functions => ({
        id: functions.id,
        name: functions.name,
        abbreviation: functions.abbreviation,
        description: functions.description,
        strategy_link: functions.strategy_link,
        classification: functions.classification,
        emoji: functions.emoji,
      }));
    }
    return [];
  }, [functions]);

  const addNewRow = () => {
    const newRow = {
      id: generateuniqueIshId(),
      name: `Function ${Array.isArray(functions) ? functions.length + 1 : 1}`,
      designCriteria: '',
      priority: ''
    };
    setFunctions({type: 'prepend', payload: [newRow] });
    return newRow;
  };

  const KeepRowButtonRenderer = ({ api, node, data, state, setState, evaluation, setEvaluation }) => {
    const handleTransfer = () => {
      setState({ type: 'prepend', payload: [data] });
      setEvaluation({ ...evaluation, recommended_functions: evaluation.recommended_functions.filter(obj => obj.id !== data.id) });
  
  
      setTimeout(() => {
        api.setRowData(data);
      }, 0);
    };
  
    return (
      <span onClick={handleTransfer} className="button__icon button__icon-green material-symbols-outlined">
        add_circle
      </span>
    );
  };

  const generateFunctions = async (
  ) => {
    if (isGeneratingFunctions) return;

    if (!validateFunctions(functions)) {
      alert('Ensure all current Functions are valid before generating.');
      return;
    }

    setIsGeneratingFunctions(true);
    const blankRow = addNewRow();
    setIsLoading({ type: 'add_to_list', payload: blankRow.id });

    const apiPayload = {
        'prompt_template': 'generate_functions',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const generatedFunctions = apiResponse.message;

      try {
        setFunctions({ type: 'prepend', payload: generatedFunctions } );
        setIsGeneratingFunctions(false);
      } catch (error) {
          console.error('Error parsing API response:', error);
          setIsGeneratingFunctions(false);
      }

    } catch (error) {
        console.error('Error generating Functions:', error);
    }
    setIsGeneratingFunctions(false);
    setIsLoading({ type: 'remove_from_list', payload: blankRow.id });
    setFunctions({ type: 'remove', payload: blankRow });
  };

const evaluateFunctions = async (
  ) => {
    if (isGeneratingFunctions) return;

    if (!validateFunctions(functions)) {
      alert('Ensure all current Functions are valid before evaluating.');
      return;
    }

    setIsEvaluatingFunctions(true);

    const apiPayload = {
        'prompt_template': 'evaluate_functions',
        'client': client,
        'business_name': name ? name : '', 
        'business_website': website ? website : '',
        'mission_statement': missionStatement ? missionStatement : '',
        'industry': businessDetails.industry ? businessDetails.industry : '',
        'strategy_arena': strategyArena ? strategyArena : '',
        'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
        'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
        'strategy_staging': strategyStaging ? strategyStaging : '',
        'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
        'design_criteria': designCriteria ? designCriteria : [],
        'blueprint': blueprint ? blueprint : {},
        'functions': functions ? functions : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const functionsEvaluation = apiResponse.message;

      try {
        setFunctionsEvaluation(functionsEvaluation);
      } catch (error) {
          console.error('Error parsing API response:', error);
      }

    } catch (error) {
        console.error('Error generating functions:', error);
    }
    setIsEvaluatingFunctions(false);
  };

  const refineFunctions = async (activity) => {
    return []; 
    /*console.log('Splitting activity:', activity);
    setIsLoading({ type: 'add_to_list', payload: [activity.id] });
    const apiPayload = {
      'prompt_template': 'split_design_criteria',
      'activity_to_split': activity.activity,
      'description': activity.description,
      'business_name': name ? name : '', 
      'business_website': website ? website : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'num_employees': businessDetails.num_employees ? businessDetails.num_employees : '',
      'revenue': businessDetails.revenue ? getRevenueFormated(businessDetails.revenue) : '',
      'designCriteria': designCriteria ? designCriteria : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const newdesignCriteria = apiResponse.message[0]['designCriteria'];
      return newdesignCriteria;
    } catch (error) {
      console.error('Error sending chat message:', error);
    } finally {
      setIsLoading({ type: 'remove_from_list', payload: [activity.id] });
    }*/
  }

  const handleImportSubmit = useCallback((data) => {
    setShowImport(false);
    let newFunctions = [];
    data.rows.forEach((row) => {
      let newRow = {
        id: generateuniqueIshId(),
        emoji: row[data.emoji],
        name: row[data.name],
        description: row[data.description],
        objective: row[data.objective],
        strategy_link: row[data.strategy_link],
        classification: row[data.classification],
      }
      if (newFunctions.find(f => f.id === newRow.id)) {
        return;
      }
      newFunctions.push(newRow);
    })

    setFunctions({ type: data.write_mode, payload: newFunctions });
  }, [setFunctions]);

  return (
    <div className="define-tab">
      <div className="function-tab-header">
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <button 
          onClick={() => 
          generateFunctions()} 
          disabled={isGeneratingFunctions} 
          className="button"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          { functions.length !== 0 ? 'Generate more functions' : 'Generate Functions' }
          {isGeneratingFunctions ? (
            <Loading style={{ marginLeft: 'auto', height: '35px' }} />
          ) : (
            <span className="material-symbols-outlined">neurology</span>
          )}
        </button>
        <button onClick={() => setShowImport(true)} className="button ms-2">
          Import Functions
          <span className="material-symbols-outlined">
            upload
          </span>
        </button>
        <ExportToExcel 
          buttonLabel='Export Functions' 
          data={functions} 
          fileName={name + ' Functions from Reconfig'} 
          sheetName={name + ' Functions'} 
          fieldsToExport={['name', 'description', 'strategy_link', 'classification']}
          className="button ms-2"
        />
        <Button
          color="primary"
          onClick={addNewRow}
          text="Add Function"
          className="button ms-2"
          icon="plus.svg#plus"
        />
        <Modal isOpen={showImport} toggle={() => setShowImport(false)}>
          <ModalHeader>Import Functions</ModalHeader>
          <CSVWizard
            toggle={() => setShowImport(false)}
            handleSubmit={handleImportSubmit}
            specifyColumns={[
              { key: 'name', label: 'Name', placeholder: 'Name', },
              { key: 'description', label: 'Description', placeholder: 'Description' },
              { key: 'strategy_link', label: 'Strategy Link', placeholder: 'Strategy Link' },
              { key: 'classification', label: 'Classification', placeholder: 'Classification' },
              { key: 'emoji', label: 'Emoji', placeholder: 'Emoji' },
            ]}
          />
        </Modal>
      </div>
      <CollapsibleEvaluationAgGrid 
        objectName="Function"
        evaluation={functionsEvaluation?.alignment_evaluation}
        evaluationQuality={functionsEvaluation?.alignment_evaluation_quality}
        coverage={functionsEvaluation?.coverage_evaluation}
        coverageQuality={functionsEvaluation?.coverage_evaluation_quality}
        columnDefs={evaluationColumns}
        rowData={functionsEvaluation?.recommended_functions}
        evaluationFunction={evaluateFunctions}
        contextMenuGeneration={refineFunctions}
        isLoading={isLoading}
        isEvaluating={isEvaluatingFunctions}
        setIsEvaluating={setIsEvaluatingFunctions}
        validatedData={validateFunctions(functions)}
      />
      <div className="mt-6" style={{'margin': '10px'}}>
        <h2>Functions</h2>
        {functions?.length} Functions
      </div>
      <Table
        objectName="Function"
        listTitle="Functions"
        list={functions}
        setList={setFunctions}
        columnDefs={functionColumns}
        rowData={rowData}
        contextMenuGeneration={refineFunctions}
        isLoading={isLoading}
      />
    </div>
  );
}

export default FunctionsTab;
